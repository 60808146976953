.social-icons {
    display: flex;
    justify-content: center;
    gap: 50px;
    font-size: 3em;
    margin-top: 20px;
}

.social-icons a {
    color: white;
    transition: color 0.3s ease;
}

.social-icons a:hover {
    color: #3a3737;
}