.stars,
.twinkling,
.clouds {
    position: relative;
    display: block;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
}

.stars {
    z-index: 0;
    background: #000 url('/src/assets/images/stars.png') repeat top center;
}

.twinkling {
    z-index: 1;
    background: transparent url('/src/assets/images/twinkling.png') repeat top center;
    animation: move-twinkle-back 200s linear infinite;
}

.clouds {
    z-index: 2;
    background: transparent url('/src/assets/images/clouds.png') repeat top center;
    animation: move-clouds-back 200s linear infinite;
}

@keyframes move-twinkle-back {
    from {
        background-position: 0 0;
    }

    to {
        background-position: -10000px 5000px;
    }
}

@keyframes move-clouds-back {
    from {
        background-position: 0 0;
    }

    to {
        background-position: 10000px 0;
    }
}