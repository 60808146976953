#intro-view {
    position: relative;
    height: 100vh;
    width: 100%;
    overflow: hidden;
}


.centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.centered-text {
    font-size: 2em;
    color: white;
    z-index: 3;
}

.buttons {
    display: flex;
    justify-content: center;
    gap: 50px;
    margin-top: 20px;
}